/* 全局布局 */
/* 示例全局样式 */
body {
  font-family: 'LXGW WenKai', 'Source Han Serif', 'Arial', sans-serif;}

/* 针对标题 */
h1, h2, h3 {
  font-family: 'LXGW WenKai', 'Source Han Serif', 'Arial', sans-serif;}

/* 针对按钮 */
button {
  font-family: 'LXGW WenKai', 'Source Han Serif', 'Arial', sans-serif;}
/* 游戏容器 */
.game-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(135deg, #ffccd5, #e6b3ff); /* 柔和的粉紫渐变 */
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  position: relative;
  padding-top: 20px;
}

/* 棋盘 */
.board {
  display: grid;
  grid-template-columns: repeat(13, 40px);
  gap: 2px;
  transform-origin: top center;
  background: rgba(255, 204, 221, 0.8); /* 半透明粉色背景 */
  border-radius: 12px;
  padding: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}


/* 棋盘格子样式 */
.square {
  position: relative;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid rgba(255, 105, 180, 0.8); /* 暖粉色边框 */
  background: rgba(255, 182, 193, 0.7); /* 柔和的粉色背景 */
  box-shadow: inset 0 0 8px rgba(255, 105, 180, 0.5); /* 内阴影 */
  border-radius: 8px; /* 圆角 */
  overflow: hidden;
  /* transition: all 0.3s ease; */
}

.arrow {
  position: absolute;
  width: 1px; /* 再次缩小为原来的 50% */
  height: 1px; /* 再次缩小为原来的 50% */
  background-color: transparent;
  border: solid rgba(255, 255, 255, 0.6); /* 轻微降低透明度 */
  border-width: 1px 1px 0 0; /* 保持箭头的形状 */
  transform: rotate(var(--angle, 0deg)) translate(5px, -5px); /* 稍微调整箭头的偏移 */
  transform-origin: center;
  z-index: 2; /* 确保箭头在棋盘元素的上方 */
}

.arrow-up {
  border-width: 0 10px 15px 10px;
  border-color: transparent transparent #ff69b4 transparent;
  top: -5px;
  left: 50%;
  transform: translateX(-50%);
}

.arrow-down {
  border-width: 15px 10px 0 10px;
  border-color: #ff69b4 transparent transparent transparent;
  bottom: -5px;
  left: 50%;  
  transform: translateX(-50%);
}

.arrow-left {
  border-width: 10px 15px 10px 0;
  border-color: transparent #ff69b4 transparent transparent;
  left: -5px;
  top: 50%;
  transform: translateY(-50%);
}

.arrow-right {
  border-width: 10px 0 10px 15px;
  border-color: transparent transparent transparent #ff69b4;
  right: -5px;
  top: 50%;
  transform: translateY(-50%);
}

/* 鼠标悬停高亮 */
.square:hover {
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0 0 10px rgba(255, 105, 180, 0.8);
  transform: scale(1.1);
}

/* 特定类型格子样式 */
.square.empty {
  background-color: rgba(255, 255, 255, 0);
  border: none;
  box-shadow: none
}


.square.path{
  background-color: rgba(251, 213, 219, 0.7);
}
.square.lady {
  background-color: rgba(255, 21, 56, 0.7);
}
.square.man1 {
  background-color: rgba(117, 104, 240, 0.7);
}
.square.man2 {
  background-color: rgba(141, 234, 129, 0.7);
};

.square.hot {
  background: linear-gradient(145deg, #ff7f50, #cd5c5c); /* 温暖的橙红和深红渐变 */
  border: 2px solid #b22222; /* 深红色边框 */
  box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.2); /* 柔和的内阴影 */
  color: white; /* 高对比文字颜色 */
  font-weight: bold;
  font-size: 14px;
  text-align: center;
}

/* 终点格子样式 */
.square.finish {
  background: linear-gradient(135deg, #ff85c0, #d580ff); /* 渐变终点 */
  border-radius: 50%;
  transform: scale(1.5);
  z-index: 3;
  box-shadow: 0 0 15px rgba(255, 105, 180, 0.8), inset 0 0 10px rgba(255, 182, 193, 0.5);
  color: white;
  font-weight: bold;
}

.empty {
  background-color: #fff;
}

.A-final {
  background-color: rgba(255, 0, 0, 0.6);
}

.B-final {
  background-color: rgba(0, 0, 255, 0.6);
}

.C-final {
  background-color: rgba(0, 128, 0, 0.6);
}

.D-final {
  background-color: rgba(255, 255, 0, 0.6);
}

/* 玩家棋子样式 */
.player {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px; /* 略小于格子大小，留出一些视觉空间 */
  height: 35px;
  border-radius: 50%; /* 完全圆形 */
  font-size: 14px; /* 调整字体大小 */
  font-weight: bold;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3), inset 0 2px 4px rgba(255, 255, 255, 0.2); /* 立体感和光泽 */
  transition: transform 0.3s ease, background-color 0.3s ease; /* 添加动画 */
}

/* 鼠标悬停时放大效果 */
.player:hover {
  transform: scale(1.2); /* 放大 1.2 倍 */
  background-color: rgba(255, 255, 255, 0.7); /* 变亮 */
}

/* 各个玩家的颜色搭配 */
.player-A {
  background: linear-gradient(145deg, #ff7eb3, #ff4d6d); /* 粉红渐变 */
  color: white; /* 字体颜色 */
  border: 2px solid #ffa3c1; /* 边框颜色 */
}

.player-B {
  background: linear-gradient(145deg, #7eb3ff, #4d6dff); /* 蓝色渐变 */
  color: white;
  border: 2px solid #a3c1ff;
}

.player-C {
  background: linear-gradient(145deg, #4caf50, #2e7d32); /* 深绿色渐变 */
  color: white;
  border: 2px solid #66bb6a; /* 边框颜色与背景协调 */
}

.player-D {
  background: linear-gradient(145deg, #ffe47e, #ffcf4d); /* 黄色渐变 */
  color: black; /* 黑色字体以增强对比 */
  border: 2px solid #ffdfa3;
}


/* 当前玩家棋子的样式 */
.player.current {
  animation: pulse 1s infinite ease-in-out, shake 0.5s infinite ease-in-out; /* 叠加两个动画 */
  box-shadow: 0 0 15px rgba(255, 255, 255, 0.8); /* 光晕效果 */
  transform-origin: center;
  will-change: transform, box-shadow;
}

/* 脉动动画：模拟颜色的“心跳” */
@keyframes pulse {
  0%, 100% {
    transform: scale(1); /* 初始大小 */
    box-shadow: 0 0 15px rgba(255, 255, 255, 0.8); /* 光晕正常 */
  }
  50% {
    transform: scale(1.2); /* 放大 */
    box-shadow: 0 0 25px rgba(255, 0, 0, 0.8); /* 光晕变强 */
  }
}

/* 摇摆动画：表现焦急 */
@keyframes shake {
  0%, 100% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-3px); /* 左摆 */
  }
  75% {
    transform: translateX(3px); /* 右摆 */
  }
}
/* 状态栏样式 */
.status-bar {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  background: rgba(255, 204, 221, 0.9);
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 10px;
  margin-bottom: 20px;
  max-width: 600px;
}

/* 当前玩家样式 */
.current-player {
  text-align: center;
  font-size: 16px;
  color: #ff69b4;
  font-weight: bold;
}


.current-player strong {
  display: block;
  font-size: 14px;
  margin-bottom: 2px;
}

.current-player span {
  font-size: 16px;
}


/* 骰子容器样式 */
.dice-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  margin: 0 auto;
  justify-self: center; /* 保持在所在网格内居中 */
}

/* 骰子图片样式 */
.dice {
  width: 100%;
  height: 100%;
  transition: transform 0.2s ease;
}

/* 按钮样式 */
.go-button {
  background: linear-gradient(135deg, #ff69b4, #ff85c0);
  color: white;
  border: none;
  padding: 8px 16px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 12px;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.go-button:disabled {
  background: #ccc;
  color: #666;
  cursor: not-allowed;
}
/* 弹窗和对话框样式 */
.dialog-overlay,
.name-dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

/* 对话框内容样式 */
.dialog-content {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  max-width: 300px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border: 1px solid #ddd;
}
.dialog-content p {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}
/* 按钮样式：与 GO 按钮一致 */
.dialog-content button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 5px;
  margin: 10px 5px;
  transition: background-color 0.3s ease;
}

.dialog-content button:hover {
  background-color: #45a049;
}
/* 确认按钮样式 */
.dialog-content button:first-of-type {
  background-color: #4CAF50;
}

/* 取消按钮样式 */
.dialog-content button:last-of-type {
  background-color: #ccc;
  color: #333;
}



.dialog-content button:last-of-type:hover {
  background-color: #bbb;
}

.name-dialog-content {
  background-color: #ffffff; /* 设置不透明白色背景 */
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  width: 300px;
}

.name-dialog-content h3 {
  margin-bottom: 10px;
}

.name-dialog-content label {
  display: block;
  margin: 10px 0 5px;
}

.name-dialog-content input {
  width: 100%;
  padding: 5px;
  margin-bottom: 10px;
}

.name-dialog-content button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
}

/* 骰子样式 */
.dice-display {
  font-size: 24px;
  margin-bottom: 20px;
}

.dice-rolling {
  animation: roll 0.5s infinite linear;
  font-size: 24px;
  color: #888;
}

.dice-number {
  font-size: 48px;
  font-weight: bold;
}

@keyframes roll {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* 响应式布局 */
@media (max-width: 768px) {
  .game-container {
    padding: 5px;
  }

  .board {
    transform: scale(0.9);
  }
}

@media (max-width: 480px) {
  .game-container {
    padding: 2px;
  }

  .board {
    transform: scale(0.75);
  }
}

/* 展示框体样式 */
.content-box {
  position: absolute;
  background-color: #ffffff;
  color: #333;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  font-size: 14px;
  max-width: 200px;
  white-space: nowrap;
  transform: translate(-50%, -100%);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

/* 飞机主体 */
.airplanea1 {
  background-image: url('../content/img/plane-a1.svg'); /* SVG 文件路径 */
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  transform: rotate(180deg); /* 旋转90度 */
  width: 40px; /* 根据需要调整宽度 */
  height: 40px; /* 根据需要调整高度 */
  border: none;
  box-shadow: none;
  background-color: transparent;
}
.airplanea2 {
  background-image: url('../content/img/plane-a2.svg'); /* SVG 文件路径 */
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  transform: rotate(180deg); /* 旋转90度 */
  width: 40px; /* 根据需要调整宽度 */
  height: 40px; /* 根据需要调整高度 */
  border: none;
  box-shadow: none;
  background-color: transparent;

}
.airplaneb1 {
  background-image: url('../content/img/plane-b1.svg'); /* SVG 文件路径 */
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  transform: rotate(270deg); /* 旋转90度 */
  width: 40px; /* 根据需要调整宽度 */
  height: 40px; /* 根据需要调整高度 */
  border: none;
  box-shadow: none;
  background-color: transparent;

}
.airplaneb2 {
  background-image: url('../content/img/plane-b2.svg'); /* SVG 文件路径 */
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  transform: rotate(270deg); /* 旋转90度 */
  width: 40px; /* 根据需要调整宽度 */
  height: 40px; /* 根据需要调整高度 */
  border: none;
  box-shadow: none;
  background-color: transparent;

}
.airplanec1 {
  background-image: url('../content/img/plane-c1.svg'); /* SVG 文件路径 */
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 40px; /* 根据需要调整宽度 */
  height: 40px; /* 根据需要调整高度 */
  border: none;
  box-shadow: none;
  background-color: transparent;

}
.airplanec2 {
  background-image: url('../content/img/plane-c2.svg'); /* SVG 文件路径 */
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 40px; /* 根据需要调整宽度 */
  height: 40px; /* 根据需要调整高度 */
  border: none;
  box-shadow: none;
  background-color: transparent;

}
.airplaned1 {
  background-image: url('../content/img/plane-d1.svg'); /* SVG 文件路径 */
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  transform: rotate(90deg); /* 旋转90度 */
  width: 40px; /* 根据需要调整宽度 */
  height: 40px; /* 根据需要调整高度 */
  border: none;
  box-shadow: none;
  background-color: transparent;

}
.airplaned2 {
  background-image: url('../content/img/plane-d2.svg'); /* SVG 文件路径 */
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  transform: rotate(90deg); /* 旋转90度 */
  width: 40px; /* 根据需要调整宽度 */
  height: 40px; /* 根据需要调整高度 */
  border: none;
  box-shadow: none;
  background-color: transparent;

}

/* 说明栏目样式 */
.game-intro {
  margin-top: 20px;
  padding: 15px;
  background: rgba(255, 204, 221, 0.8);
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  font-size: 16px;
  color: #ff69b4;
}

/* 提示框的样式 */
.tooltip {
  position: absolute;
  background-color: #ffe4e1; /* 与现有主题一致的柔和粉色背景 */
  color: #8B5FBF; /* 暧昧紫色字体 */
  padding: 8px 12px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: bold;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* 轻微阴影增强层次感 */
  pointer-events: none; /* 不干扰鼠标事件 */
  z-index: 1000;
  white-space: nowrap; /* 避免文字换行 */
  transform: translate(-50%, -150%); /* 调整到鼠标上方 */
  border: 1px solid #f8c8dc; /* 浅粉色边框 */
  transition: opacity 0.3s ease, transform 0.2s ease;
  opacity: 0.95; /* 设置透明度为柔和效果 */
}

/* 包裹返回按钮和状态栏的容器 */
.status-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 20px; /* 状态栏和棋盘之间的间距 */
}

/* 返回按钮样式 */
.back-button {
  display: inline-block;
  margin-right: 10px; /* 与状态栏的间距 */
  background-color: linear-gradient(135deg, #ffccd5, #e6b3ff); /* 与状态栏背景一致 */
  color: #333; /* 文本颜色 */
  text-decoration: none;
  padding: 8px 16px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* 柔和阴影 */
  border: 1px solid #ddd; /* 统一边框样式 */
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.back-button:hover {
  background-color: #fff; /* 浅色悬停效果 */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15); /* 悬停时增强阴影 */
}
